jQuery(document).ready(function () {

    jQuery(function() {
        jQuery('.products__item').matchHeight();
    });

    jQuery('.image-slider').slick({

		infinite: true,

		arrows: true,

		dots: false,

		speed: 500,

		lazyLoad: 'ondemand',

		autoplay: false,

		autoplaySpeed: 4000,

		useTransform: true,

        pauseOnHover: false,

        prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="fas fa-caret-left"></i></button>',

		nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="fas fa-caret-right"></i></button>',

        asNavFor: '.text-slider'

    });

    

    jQuery('.text-slider').slick({

		infinite: true,

		arrows: false,

		dots: false,

		speed: 500,

		autoplay: false,

		autoplaySpeed: 4000,

		useTransform: true,

        pauseOnHover: false,

        fade: true,

        cssEase: 'linear',

        asNavFor: '.image-slider',

    });



    jQuery('.product-info__gallery').slick({

		infinite: false,

		arrows: true,

		dots: false,

		speed: 500,

		lazyLoad: 'ondemand',

		autoplay: false,

		autoplaySpeed: 4000,

		useTransform: true,

        pauseOnHover: false,

        asNavFor: '.product-info__gallery_nav',

        prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="fas fa-angle-left"></i></i></button>',

		nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="fas fa-angle-right"></i></button>',

    });

    jQuery('.product-info__gallery_nav').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.product-info__gallery',
        dots: false,
        arrows: false,
        focusOnSelect: true
      });




    jQuery('.product-addons__slider').slick({

		infinite: true,

		arrows: true,

		dots: false,

		speed: 500,

		lazyLoad: 'ondemand',

		autoplay: false,

		autoplaySpeed: 4000,

		useTransform: true,

        pauseOnHover: false,

        prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="fas fa-angle-left"></i></i></button>',

        nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="fas fa-angle-right"></i></button>',

        slidesToShow: 2,

        slidesToScroll: 1,

        responsive: [

            {

            breakpoint: 1024,

            settings: {

                slidesToShow: 2,

                slidesToScroll: 1,

                infinite: true,

            }

            },

            {

                breakpoint: 922,

                settings: {

                    slidesToShow: 1,

                    slidesToScroll: 1

            }

            },

            {

                breakpoint: 768,

                settings: {

                    slidesToShow: 2,

                    slidesToScroll: 1

            }

            },

            {

            breakpoint: 600,

            settings: {

                slidesToShow: 2,

                slidesToScroll: 1

            }

            },

            {

            breakpoint: 480,

            settings: {

                slidesToShow: 1,

                slidesToScroll: 1

            }

            }

        ]

    });



    jQuery('.back-to-top a').on('click', function(e) { 

        jQuery('html,body').animate({ scrollTop: jQuery("body").offset().top - 10},'slow');



		return false;

	});



    if (jQuery(".footer__map").length) {

		var marker_data = JSON.parse(ajax.data);



		get_map('map',marker_data.loc_lat,marker_data.loc_lng,marker_data.marker,marker_data);

    }



    jQuery('.mobile-menu__open-btn').on('click', function(e) { 

        jQuery('body').toggleClass('menu-open');



		return false;

	});



	jQuery('.mobile-menu__close-btn').on('click', function(e) { 

		jQuery('body').toggleClass('menu-open');



		return false;

    });

    

    jQuery('.menu-item-has-children>a').on('click', function(e) { 

		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) == true ) {

            jQuery(this).parent().toggleClass('submenu-open');



			return false;

		}

    });

    

    jQuery('table:not([class])').wrap('<div class="responsive-table"></div>');



    jQuery( '.single_variation' ).on( 'visibility', function() {

		var $element = jQuery( this );

		var timer = setInterval( function() {

			if( $element.is( ':hidden' ) ) {

				jQuery('.product-info__summary').removeClass('variation-visible');

			} else {

				jQuery('.product-info__summary').addClass('variation-visible');

			}

		}, 300 );

    }).trigger( 'visibility' );

    

    if (jQuery("#filters_regen").length) {

        resolve_filters();

    }else{

        jQuery('.filters__select').select2({

            minimumResultsForSearch: -1

        });



        jQuery('.filters__label').show();

        jQuery('.filters__products-main-filter').show();

    }

});



function resolve_filters(){

    var data = jQuery('#filters_regen').data('filters');



    jQuery('.filters__main-wrap').append('<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>');



    jQuery.ajax({

        url: ajax.url,

        type: "POST",

        data: {

            action: "ajax_resolve_filters",

            data_string: data

        },

        success: function(data){

            if(data != null && data != ''){

                var filters_object = jQuery.parseJSON(data);



                jQuery('.filters__select').each(function(i, obj) {

                    var select_name = jQuery(this).attr('name');

                    var select_field = jQuery(this);

                    var select_options = select_field.find('option');



                    jQuery.each(filters_object, function(name, values) {

                        if( name == select_name ){

                            remove_options(select_field, values);

                        }

                    });

                });



                jQuery('.lds-roller').remove();



                jQuery('.filters__select').select2({

                    minimumResultsForSearch: -1

                });



                jQuery('.filters__label').show();

                jQuery('.filters__products-main-filter').show();

            }

        },

        error: function(errorThrown){

            console.log(errorThrown);

        }

    });

}



function remove_options(select_field, values){

    var select_options = select_field.find('option');



    jQuery.each(select_options, function(key, value) {

        var option = jQuery(this);

        var title = value.text;



        var exists = false;



        jQuery.each(values, function(filter_name, filter_value) {

            var option_title = filter_value.title;

            if( title === option_title ){

                exists = true;

            }

        });



        if( exists == false ){

            option.remove();

        }

    });

}



function get_map(id,lat,lng,marker,marker_data){

    var LatLng = new google.maps.LatLng(lat, lng);

		

	var myOptions = {

	  center: LatLng,

	  zoom: 14,

	  styles: [

                {

                    "featureType": "administrative",

                    "elementType": "all",

                    "stylers": [

                        {

                            "saturation": "-100"

                        }

                    ]

                },

                {

                    "featureType": "administrative.province",

                    "elementType": "all",

                    "stylers": [

                        {

                            "visibility": "off"

                        }

                    ]

                },

                {

                    "featureType": "landscape",

                    "elementType": "all",

                    "stylers": [

                        {

                            "saturation": -100

                        },

                        {

                            "lightness": 65

                        },

                        {

                            "visibility": "on"

                        }

                    ]

                },

                {

                    "featureType": "poi",

                    "elementType": "all",

                    "stylers": [

                        {

                            "saturation": -100

                        },

                        {

                            "lightness": "50"

                        },

                        {

                            "visibility": "simplified"

                        }

                    ]

                },

                {

                    "featureType": "road",

                    "elementType": "all",

                    "stylers": [

                        {

                            "saturation": "-100"

                        }

                    ]

                },

                {

                    "featureType": "road.highway",

                    "elementType": "all",

                    "stylers": [

                        {

                            "visibility": "simplified"

                        }

                    ]

                },

                {

                    "featureType": "road.arterial",

                    "elementType": "all",

                    "stylers": [

                        {

                            "lightness": "30"

                        }

                    ]

                },

                {

                    "featureType": "road.local",

                    "elementType": "all",

                    "stylers": [

                        {

                            "lightness": "40"

                        }

                    ]

                },

                {

                    "featureType": "transit",

                    "elementType": "all",

                    "stylers": [

                        {

                            "saturation": -100

                        },

                        {

                            "visibility": "simplified"

                        }

                    ]

                },

                {

                    "featureType": "water",

                    "elementType": "geometry",

                    "stylers": [

                        {

                            "hue": "#ffff00"

                        },

                        {

                            "lightness": -25

                        },

                        {

                            "saturation": -97

                        }

                    ]

                },

                {

                    "featureType": "water",

                    "elementType": "labels",

                    "stylers": [

                        {

                            "lightness": -25

                        },

                        {

                            "saturation": -100

                        }

                    ]

                }

            ]

	};



	var marker;

	var map = new google.maps.Map(document.getElementById(id), myOptions);



    var infowindow = new google.maps.InfoWindow({

        pixelOffset: new google.maps.Size(0, 0)

    });



    var content = '';

        content += '<div id="infowindow-content">';

        content += '<img src="'+ marker_data.logo +'" class="info-logo" />';

        content += '<div class="info-text">';

        content += '<p>'+ marker_data.loc_adress + '</p>';

        content += '<p>Tel.: '+ marker_data.phone + '</p>';

        content += '<p>'+ marker_data.fax_label +' '+ marker_data.fax +'</p>';

        content += '<p>'+ marker_data.email_label +' '+ marker_data.email +'</p>';

        content += '<p class="last-line"><strong>'+ marker_data.work_hours_label +'</strong> '+ marker_data.work_hours +'</p>';

        content += '</div>';

        content += '</div>';



	marker = new google.maps.Marker({

	  position: LatLng,

	  map: map,

	  icon: marker

    });

    

    google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){ 

        return function() {

            infowindow.setContent(content);

            infowindow.open(map,marker);

  

            jQuery('#infowindow-content').parent().parent().parent().parent().addClass("infowindow-content-wrapper");

        };

    })(marker,content,infowindow));

  

    google.maps.event.addDomListener(window, "resize", function () {

        map.panTo(LatLng);

    });

  

    infowindow.setContent(content);  

    infowindow.open(map,marker);

  

    setTimeout(

        function(){

            var wrapper = jQuery('#'+id).find('#infowindow-content');

  

            wrapper.parent().parent().parent().parent().addClass("infowindow-content-wrapper");  

    }, 800);

}